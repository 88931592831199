@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  --clr-border-dk: rgb(72, 71, 71);
  --clr-bkgrnd: rgb(20, 20, 20);
  --clr-correct: rgb(63, 150, 52);
  --clr-incorrect: rgb(66, 65, 65);
  --clr-close: rgb(177, 179, 36);
  --clr-white: white;
}

body {
  height: 100vh;
  /* display: flex; */
  background-color: var(--clr-bkgrnd);
  color: var(--clr-white);
  font-size: 2.25em;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  overflow: hidden;
}

.content {
  display: grid;
  /* grid-template-rows: 64px auto 240px; */
  grid-template-rows: auto 1fr auto;
  /* min-height: 100vh; */
  min-height: 100%;
  width: 100%;
  padding: 0;
  height: 100vh;
  /* grid-template-columns: 100%; */
}
/* .header.navbar {
  display: flex;
  justify-content: space-between;
  background-color: orange;
} */

.header {
 /* display: flex; */
 width: 100%;
 /* justify-content: end; */
 /* background-color: orange; */
 /* align-items: start; */
  grid-row: 1 / 2;
  /* display: flex; */
  /* width: 100%; */
  padding: 0;
  border-bottom: 1px solid var(--clr-border-dk);
 
}

.navigation-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
}

.header-section {
  /* width: 100%; */
  width: 33%;
  /* height: 64px; */
  /* background-color: blue; */
}

.header-section .title {
  text-align: center;
  cursor: pointer;
}

/* .navbar-actions {
  background-color: rgb(0, 160, 0);
  width: 100px;
} */

.drawer-only {
  /* visibility: hidden; */
  display: none;
  
}

.btn-header {
  /* align-self: center; */
  /* height: .75em; */
  fill: var(--clr-white);
  border-radius: 8px;
  /* padding-right: 12px; */
}
.me-auto {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  /* background-color: yellow; */
}

.navbar-collapse {
  display: flex;
  justify-content: flex-end;
  width: 33%;
  /* background-color: rgb(0, 116, 139); */
}

.nav {
  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 16px;
  align-items: center;
  /* background-color: aquamarine; */
}


.external-links {
  /* background-color: purple; */
  /* align-items: center; */
  display: flex;
}

.header-spacer {
  width: 33%;
}
.contact-link {
  height: 0.75em;
  fill: var(--clr-white);
  /* padding-right: 12px; */
  
}

.game-container {
  /* grid-row: 1 / 2; */
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  
  justify-content: center;
  /* justify-content: spa; */
  /* overflow: auto; */
  margin-top: 16px;
}
.settings {
margin-bottom: auto;
}

.modal-card {
  background-color: var(--clr-bkgrnd);
  background-color: var(--clr-bkgrnd);
  border: none;
  border-radius: 0px;
  color: var(--clr-white);
}

.modal-header {
  color: var(--clr-white);
}

.btn {
  border: none;
  background-color: var(--clr-bkgrnd);
  color: var(--clr-white);
  font-size: 0.75em;
}

.btn-close {
  color: var(--clr-white);
}

.game-board {
  /* grid-row: 1 /2; */
  /* grid-row: 2 /3; */
  margin-bottom: auto;
  display: flex;
  flex-shrink: inherit;
  flex-direction: column;
  justify-content: center;
  height: min(414px, 100%);
  gap: 6px;
}

.row {
  display: flex;
  gap: 6px;
  height: 100%;
}

.box-container {
  display: flex;
}

.box {
  display: flex;
  flex: 1;
  min-width: 26px;
  min-height: 26px;
  border: 2px solid var(--clr-border-dk);
  justify-content: center;
  align-items: center;
}

.keyboard-container {
  display: flex;
  /* grid-row: 2 / 3; */
  grid-row: 3 / 4;
  /* align-items: center; */
  /* height: 100px; */
  justify-content: center;
  /* background-color: blue; */
}

.keyboard {
  display: flex;
  /* justify-self: center; */
  font-size: 0.5em;
  font-weight: 400;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  align-self: flex-end;
  margin: 12px 0;
  /* max-width: 100vw; */
  /* max-width: 100px; */
  /* height: 300px; */
  /* background-color: red; */
}

.keyboard-row {
  display: flex;
  gap: 6px;
}

.keyboard-key {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  width: 48px;

  height: 64px;
  background-color: rgb(158, 158, 158);
  border-radius: 4px;
  cursor: pointer;
}

.enter {
  padding: 0 36px;
}

.del {
  padding: 0 36px;
}

.incorrect {
  background-color: var(--clr-incorrect);
  border-color: var(--clr-incorrect);
}

.correct {
  background-color: var(--clr-correct);
  border-color: var(--clr-correct);
}

.close {
  background-color: var(--clr-close);
  border-color: var(--clr-close);
}

/* @media (max-width: 768px) {
  .header-section .title {
    position: absolute;
    top: 10px;
    left: 16px;
  }
  
} */


@media (max-width: 767px) {
/* @media (max-width: 540px) { */
  .header-section .title {
    position: absolute;
    top: 10px;
    left: 16px;
  }
  
  .title {
    font-size:large;
    /* font-size:medium; */
  }
  
  .header-section{
   
    width: 100%;
    /* background-color: blue; */
  }

  .nav {

    /* width: 100%; */
    
    justify-content: flex-start;
    margin: 8px;
    padding-top: 10px;
    /* background-color: red; */
  }


  .toggle-container {
    height: 42px;
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
  }


  .external-links {
    display: flex;
    width: 100%;
    /* height: 33px; */
    justify-content: center;
    /* justify-content: flex-end; */
    /* background-color: blue; */
  }
  
 

  .desktop-only {
    visibility: hidden;
  }

  .drawer-only {
    /* visibility: visible; */
    display:contents
  }


  .container {
    width: 100vw;
    width: 100%;
    /* visibility: hidden; */
  }

  .drawer-settings {
    display: flex;
    width: 100%;
    /* height: 33px; */
    justify-content: center;
  }

  .keyboard {
    width: 100vw;
    gap: calc(100vw / 100);
  }

  .keyboard-row {
    gap: calc(100vw / 100);
  }

  .enter {
    padding: 0 calc(100vw / 16);
  }
  
  .del {
    padding: 0 calc(100vw / 16);
  }

  .keyboard-key {
    width: calc(100vw / 12);
    height: calc(100vw / 9);
    font-size: calc(100vw / 24) ;
  }
}
